import React, { useEffect, useState } from 'react';
import PointageService from './service/Pointage.jsx';
import Table from '../../components/table/index.jsx';
import moment from 'moment';
import Button from '@mui/material/Button';
// import { useAuth } from '../../applications/hooks/UseAuth';
import Notification from '../../components/notificationComponent/index.jsx';
import { CircularProgress } from '@material-ui/core';
import ModalComponent from '../../components/modalComponent/index.jsx';
import FormControl from '@material-ui/core/FormControl';
import Controls from '../../components/Entry/Controls.jsx';
import useStyles from './style.jsx';
import ButtonComponent from '../../components/Entry/Button.jsx';
import { FaFingerprint } from "react-icons/fa"
import SvgExcel from '../../components/Entry/svgExcel.jsx';

// const CURRENT_PAGE = 'pointage';

function ModalContentBody(props) {
    const { isSubmitted, dateCalcul, setDateCalcul } = props;

    const checkDateStartEmpty = () => {
        if (isSubmitted && !dateCalcul.dateStart) {
            return 'Ce champ est requis.'
        }
    }
    const checkDateEndEmpty = () => {
        if (isSubmitted && !dateCalcul.dateEnd) {
            return 'Ce champ est requis.'
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setDateCalcul({
            ...dateCalcul,
            [name]: value
        })
    }

    return (
        <form>
            <FormControl style={{ width: '100%', marginBottom: '30px' }}>
                <Controls.DatePicker
                    name="dateStart"
                    label="Date début"
                    value={dateCalcul.dateStart}
                    onChange={handleInputChange}
                    error={checkDateStartEmpty()}
                />
                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-15px' }}>{checkDateStartEmpty()}</span>
            </FormControl>
            <FormControl style={{ width: '100%' }}>
                <Controls.DatePicker
                    name="dateEnd"
                    label="Date fin"
                    value={dateCalcul.dateEnd}
                    onChange={handleInputChange}
                    error={checkDateEndEmpty()}
                />
                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-15px' }}>{checkDateEndEmpty()}</span>
            </FormControl>
        </form>
    )
}

function Pointage() {
    // const { haveAccessTo } = useAuth();
    const [rows, setRows] = useState([]);
    const classes = useStyles();
    const [openNotif, setOpenNotif] = useState(false);
    const [messageNotif, setMessageNotif] = useState('');
    const [severityPerso, setSeverityPerso] = useState("");
    const [isClickExport, setIsClickExport] = useState(false);
    const [openCalcul, setOpenCalcul] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const initialDate = {
        dateStart: '',
        dateEnd: ''
    }
    const [dateCalcul, setDateCalcul] = useState(initialDate);

    const getListEmploye = async () => {
        const response = await PointageService.getAllPointage()
        const newRows = []
        if (response?.status?.code === 200) {
            response?.data?.forEach(el => {
                newRows.push({
                  id: el.id,
                  matricule: el.matricule,
                  fullName: el.lastname + ' ' + el.firstname,
                  date: moment(el.date).format('DD MMMM YYYY'),
                  startAM: el.startAM,
                  endAM: el.endAM,
                  startPM: el.startPM,
                  endPM: el.endPM,
                });
            });
        }
        setRows(newRows);
    }

    useEffect(() => {
        getListEmploye();
    }, [])

    const handleCloseNotif = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageNotif("");
        setOpenNotif(false);
        setSeverityPerso("");
    };

    const columns = [
        {
            field: 'matricule',
            headerName: 'Matricule',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'fullName',
            headerName: 'Nom et Prénoms',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'date',
            headerName: 'Date',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'startAM',
            headerName: 'Début matin',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'endAM',
            headerName: 'Fin matin',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'startPM',
            headerName: 'Début après midi',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'endPM',
            headerName: 'Fin après midi',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        }
    ]

    const onExportExcel = async () => {
        setIsClickExport(true);
        PointageService.generateExcel({}).then((res) => {
          setIsClickExport(false);
          if (res?.status?.code === 200) {
              let a = document.createElement('a');
              a.href = 'data:application/octet-stream;base64,' + res.data.content;
              a.download = `pointages.xlsx`;
              a.click();
          }
        });
    };

    const confirmCalcul = async () => {
        setIsSubmitted(true);
        if (dateCalcul.dateStart && dateCalcul.dateEnd) {
            PointageService.calculHs(dateCalcul).then((res) => {
                if(res?.status?.code === 200) {
                    setIsSubmitted(false);
                    setOpenCalcul(false);
                    setDateCalcul(initialDate);
                    setOpenNotif(true);
                    setMessageNotif(res?.status?.message);
                    setSeverityPerso("success");
                  }
            });
        }
    }

    return (
        <>
            <Notification
                severity={severityPerso}
                openNotif={openNotif}
                handleClose={handleCloseNotif}
                message={messageNotif}
            />
            <div style={{ padding: 20 }}>
                <div className={classes.headContent}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.iconTitle}>
                            <FaFingerprint style={{ fontSize: '1.1rem' }} />
                        </div>
                        <div>
                            <h4>Pointage</h4>
                            <ButtonComponent style={{marginLeft: '20px', textTransform: 'none'}} text="Calcul HS" variant="contained" onClick={() => setOpenCalcul(true)}/>
                        </div>
                    </div>
                    <div>
                        <Button
                            disabled={isClickExport}
                            style={{ textTransform: 'capitalize', opacity: isClickExport ? '0.5' : '1' }}
                            variant="text"
                            size="small"
                            onClick={onExportExcel}
                        >
                            {
                                isClickExport &&
                                <CircularProgress className={classes.progress} color="inherit" />
                            }
                            <SvgExcel />
                        </Button>
                    </div>
                </div>
                <Table columns={columns} rows={rows}/>
            </div>
            <ModalComponent
                openModal={openCalcul}
                close={() => {
                        setOpenCalcul(false);
                        setIsSubmitted(false);
                        setDateCalcul(initialDate);
                    }}
                title={'Calcul heure supplémentaire entre deux date'}
                submit={() => confirmCalcul()}
                actionTitle={'Confirmer'}
                modalContentBody={<ModalContentBody dateCalcul={dateCalcul} setDateCalcul={setDateCalcul} isSubmitted={isSubmitted}/>}
            />
        </>
    )
}

export default Pointage;