import React, { useState } from 'react'
import ImportDataService from './service/ImportData.jsx'
// import { useAuth } from '../../applications/hooks/UseAuth';
import Notification from '../../components/notificationComponent/index.jsx'
import dataModels from './models.jsx'
import Controls from '../../components/Entry/Controls'
import { Button } from '@mui/material'
import { RiFileExcel2Line } from 'react-icons/ri'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import useStyles from './style.jsx'
import { TbDatabaseImport } from "react-icons/tb"

// const CURRENT_PAGE = 'importData';

function ImportData() {
    const models = dataModels.employeModel()
    const classes = useStyles();
    // const { haveAccessTo } = useAuth();
    // haveAccessTo(CURRENT_PAGE, 'add');
    const [openNotif, setOpenNotif] = useState(false)
    const [severityPerso, setSeverityPerso] = useState('')
    const [messageNotif, setMessageNotif] = useState('')
    const [selectedModel, setSelectedModel] = useState(null)
    const [dataSave, setDataSave] = useState([])
    const [dataSaveZip, setDataSaveZip] = useState('')
    const [activeSave, setActiveSave] = useState(false)
    const [errorList, setErrorList] = useState([])

    const handleCloseNotif = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setMessageNotif('')
        setOpenNotif(false)
        setSeverityPerso('')
    }

    const handleModelChange = (e) => {
        setSelectedModel(models.find((item) => item.tableName === e.target.value))
        setActiveSave(false);
        setErrorList([]);
    }
    
    const onChangeFile = async (e) => {
        if (e.target.files[0] !== undefined) {
            var response;
            // eslint-disable-next-line
            switch (selectedModel.tableName) {
                case 'employe':
                response = await ImportDataService.verifyDataEmploye(e.target.files[0]);
                break;
                case 'conge':
                response = await ImportDataService.verifyDataConge(e.target.files[0]);
                break;
                case 'abs':
                response = await ImportDataService.verifyDataAbs(e.target.files[0]);
                break;
                case 'pointage':
                response = await ImportDataService.verifyDataPointage(e.target.files[0]);
                break;
                case 'document':
                response = await ImportDataService.verifyDataZip(e.target.files[0]);
                break;
            }
            if(response?.status?.code === 200) {
                setActiveSave(true);
                if (selectedModel?.tableName === 'document') {
                    setDataSaveZip(response.data);
                } else {
                    setDataSave(response.data);
                }
            } else {
                if(response.data === null) {
                    setErrorList([]);
                    setMessageNotif(response.status.message);
                    setOpenNotif(true);
                    setSeverityPerso('error');
                    setActiveSave(false);
                } else {
                    setErrorList(response.data);
                    setActiveSave(false);
                }
            }
        }
    }

    const saveDataArray = async () => {
        if (activeSave) {
            var response;
            // eslint-disable-next-line
            switch (selectedModel.tableName) {
                case 'employe':
                response = await ImportDataService.saveEmploye(dataSave);
                break;
                case 'conge':
                response = await ImportDataService.saveConge(dataSave);
                break;
                case 'abs':
                response = await ImportDataService.saveAbs(dataSave);
                break;
                case 'pointage':
                response = await ImportDataService.savePointage(dataSave);
                break;
                case 'document':
                response = await ImportDataService.saveDataZip(dataSaveZip);
                break;
            }
            if(response?.status?.code === 200) {
                setMessageNotif(response.status.message);
                setOpenNotif(true);
                setSeverityPerso('success');
                setActiveSave(false);
            } else {
                setMessageNotif(response.status.message);
                setOpenNotif(true);
                setSeverityPerso('error');
                setActiveSave(false);
            }
        }
    }

    return (
        <>
            <Notification
                severity={severityPerso}
                openNotif={openNotif}
                handleClose={handleCloseNotif}
                message={messageNotif}
            />
            <div style={{ padding: 20 }}>
                <div className={classes.headContent}>
                    <div className={classes.iconTitle}>
                        <TbDatabaseImport style={{ fontSize: '1.1rem' }} />
                    </div>
                    <div>
                        <h4>Importation de donnée</h4>
                        <span>Gestion de l'importation de données</span>
                    </div>
                </div>
                <Controls.AutoCompleteSelect
                    label="Modèle de données"
                    name="model"
                    options={models || []}
                    labelKey="label"
                    valueKey="tableName"
                    onChange={handleModelChange}
                    value={selectedModel?.tableName}
                />
                {selectedModel && (
                    <>
                        {selectedModel?.tableName !== 'document' &&
                            <>
                                <h4 style={{ margin: 0 }}>Format xlsx</h4>
                                <div style={{ width: '100%', overflowX: 'auto' }}>
                                    <table>
                                        <thead>
                                            <tr>
                                                {selectedModel?.properties.map((item) =>
                                                    (item.label &&
                                                        <th style={{ fontSize: '12px', border: '1px solid' }} key={item.id}>
                                                            {item.label}
                                                        </th>
                                                    )
                                                )}
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </>
                        }
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* <Button sx={{textTransform: "none"}} color="primary" variant="contained">
                                <RiFileExcel2Line style={{ marginRight: '5px' }} />
                                Importer {selectedModel?.tableName === 'document' ? '(.zip)' : '(.xlsx)'}
                                <input
                                    hidden
                                    type="file"
                                    accept=".xlsx,.xls,.zip"
                                    onChange={(e) =>
                                        onChangeFile(e)
                                    }
                                />
                            </Button> */}
                            <Button variant="contained" component="label">
                                <RiFileExcel2Line style={{ marginRight: '5px' }} />
                                Importer {selectedModel?.tableName === 'document' ? '(.ZIP)' : '(.XLSX)'}
                                <input
                                    hidden
                                    type="file"
                                    accept=".xlsx,.xls,.zip"
                                    onChange={(e) =>
                                        onChangeFile(e)
                                    }
                                />
                            </Button>
                            {activeSave &&
                                <Button variant="contained" onClick={() => saveDataArray()} component="label">
                                    Savegarder
                                </Button>
                            }
                        </div>
                        {/* <Controls.FileInput
                            onClick={handleFileVerify}
                            onChange={onChangeFile}
                            label={'Vérifier'}
                            type="xlsx"
                            processCompleted={scanCompleted}
                        /> */}
                    </>
                )}
                {(errorList.length !== 0 && selectedModel?.tableName === 'document') ?
                    (<List sx={{ width: '100%' }}>
                        <ListItem>
                            <span style={{ width: '175px', fontWeight: 'bold' }}>
                                Matricule introuvable :
                            </span>
                            &nbsp;
                            <span className={classes.listItemText2}>
                                {errorList.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {item + ', '}
                                        </React.Fragment>
                                    )
                                })}
                            </span>
                        </ListItem>
                    </List>)
                    :
                    (<List sx={{ width: '100%' }}>
                        {errorList.map((item, index) => {
                            return  item.ligne &&
                                    <ListItem key={index}>
                                        <span style={{ maxWidth: '90px', fontWeight: 'bold' }}>
                                            {`Ligne ${item.ligne}:`}
                                        </span>
                                        &nbsp;
                                        <span className={classes.listItemText}>
                                            {selectedModel.properties.map((el) =>
                                                (item[el.id] !== undefined ? item[el.id] + ", " : "")
                                            )}
                                        </span>
                                    </ListItem>
                        })}
                    </List>)
                }
            </div>
        </>
    )
}

export default ImportData
