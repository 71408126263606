import React, { useEffect, useState } from 'react';
import AbsService from './service/Abs.jsx';
import Table from '../../components/table/index.jsx';
import moment from 'moment';
import Button from '@mui/material/Button';
import ModalComponent from '../../components/modalComponent/index.jsx';
import { useAuth } from '../../applications/hooks/UseAuth';
import Notification from '../../components/notificationComponent/index.jsx';
import Controls from '../../components/Entry/Controls.jsx';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '../../components/Autocomplete';
import { CircularProgress } from '@material-ui/core';
import useStyles from './style.jsx';
import ButtonComponent from '../../components/Entry/Button.jsx';
import { GiStabbedNote } from "react-icons/gi"
import SvgExcel from '../../components/Entry/svgExcel.jsx';

const CURRENT_PAGE = 'absences';

function getBeginEndDateOnMonth(currentDate) {
    let date = new Date(currentDate);
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    
    let start = firstDay;
    let end = lastDay;
    return {
      start,
      end
    }
}

function ModalContentBody(props) {
    const {valuesAbs, setValuesAbs, isSubmitted, options} = props;
    const classes = useStyles();
    const [fullName, setFullName] = useState({
        lastName: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setValuesAbs({
            ...valuesAbs,
            [name]: value
        })
    }
    
    const additionDayAbs = (e) => {
        let nbrDayEdit = e.target.value;
        let date = new Date(valuesAbs.startAbs);
        let endDate;
        if(valuesAbs.startJourneyAbs === 'AM' && nbrDayEdit % 1 === 0) {
            endDate = date.setDate(date.getDate() + parseFloat(nbrDayEdit) - 1);
        } else if (valuesAbs.startJourneyAbs === 'PM' && nbrDayEdit % 1 === 0) {
            endDate = date.setDate(date.getDate() + parseFloat(nbrDayEdit));
        } else if (valuesAbs.startJourneyAbs === 'AM' && nbrDayEdit > 1 && nbrDayEdit % 1 !== 0) {
            endDate = date.setDate(date.getDate() + parseFloat(nbrDayEdit));
        } else if (valuesAbs.startJourneyAbs === 'PM' && nbrDayEdit > 1 && nbrDayEdit % 1 !== 0) {
            endDate = date.setDate(date.getDate() + parseFloat(nbrDayEdit));
        }

        let dateStart = date;
        let dateEnd = new Date(endDate);
        // eslint-disable-next-line
        if (valuesAbs.startJourneyAbs === 'AM' && nbrDayEdit == 0.5) {
            setValuesAbs({
            ...valuesAbs,
            nbrDayAbs: parseFloat(nbrDayEdit),
            endAbs: dateStart,
            endJourneyAbs: 'AM'
            });
        }
        // eslint-disable-next-line
        if (valuesAbs.startJourneyAbs === 'AM' && nbrDayEdit == 1) {
            setValuesAbs({
            ...valuesAbs,
            nbrDayAbs: parseFloat(nbrDayEdit),
            endAbs: dateStart,
            endJourneyAbs: 'PM'
            });
        }
        if (valuesAbs.startJourneyAbs === 'AM' && nbrDayEdit > 1 && nbrDayEdit % 1 === 0) {
            setValuesAbs({
            ...valuesAbs,
            nbrDayAbs: parseFloat(nbrDayEdit),
            endAbs: dateEnd,
            endJourneyAbs: 'PM'
            });
        }

        if (valuesAbs.startJourneyAbs === 'AM' && nbrDayEdit > 1 && nbrDayEdit % 1 !== 0) {
            setValuesAbs({
            ...valuesAbs,
            nbrDayAbs: parseFloat(nbrDayEdit),
            endAbs: dateEnd,
            endJourneyAbs: 'AM'
            });
        }

        if (valuesAbs.startJourneyAbs === 'PM' && nbrDayEdit > 1 && nbrDayEdit % 1 !== 0) {
            setValuesAbs({
            ...valuesAbs,
            nbrDayAbs: parseFloat(nbrDayEdit),
            endAbs: dateEnd,
            endJourneyAbs: 'PM'
            });
        }
        // eslint-disable-next-line
        if (valuesAbs.startJourneyAbs === 'PM' && nbrDayEdit == 0.5) {
            setValuesAbs({
            ...valuesAbs,
            nbrDayAbs: parseFloat(nbrDayEdit),
            endAbs: dateStart,
            endJourneyAbs: 'PM'
            });
        }
        if (valuesAbs.startJourneyAbs === 'PM' && nbrDayEdit % 1 === 0) {
            setValuesAbs({
            ...valuesAbs,
            nbrDayAbs: parseFloat(nbrDayEdit),
            endAbs: dateEnd,
            endJourneyAbs: 'AM'
            });
        }
    }

    const checkEmployeeNumberEmptyAbs = () => {
        if (isSubmitted && !valuesAbs.employeeId) {
          return 'Le champ matricule est requis.'
        }
    }
    
    const checkDateStartEmptyAbs = () => {
        if (isSubmitted && !valuesAbs.startAbs) {
            return 'Le champ date début est requis.'
        }
    }
    
    const checkDateEndEmptyAbs = () => {
        if (isSubmitted && !valuesAbs.endAbs) {
            return 'Le champ Date fin est requis.'
        }
    }

    const checkAnotherReasonEmptyAbs = () => {
        if (isSubmitted && !valuesAbs.anotherReasonAbs) {
            return 'Ce champ est requis.'
        }
    }

    const checkNbrDayAbsEmptyAbs = () => {
        if (isSubmitted && valuesAbs.nbrDayAbs <= 0) {
            return 'Le nombre de jour doit être suppérieur à zéro'
        }
    }

    const onTagsChangeAbs = (event, values) => {
        if (values !== null) {
            setValuesAbs({ ...valuesAbs, employeeId: values.id });
            setFullName({ ...fullName, lastName: values.name });
        } else {
            setValuesAbs({ ...valuesAbs, employeeId: null });
            setFullName('');
        }
    }

    return (
        <form>
            <FormControl style={{ width: '100%', marginBottom: '10px', position: 'relative' }}>
                <Autocomplete
                    label={'N° Matricule'}
                    editMat={null}
                    onTagsChange={onTagsChangeAbs}
                    options={options}
                    lastName={fullName.lastName}
                />
                <span style={{ color: 'red', position: 'absolute', bottom: '-10px' }}>{checkEmployeeNumberEmptyAbs()}</span>
            </FormControl>
            <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: '10px' }}>
                <Controls.DatePicker
                    name="startAbs"
                    label="Date de début"
                    value={valuesAbs.startAbs}
                    onChange={handleInputChange}
                    error={checkDateStartEmptyAbs()}
                />
                <div className={classes.selectHours} >
                    <label style={{ fontSize: '14px', }}>AM/PM:</label>
                    <select
                        className={classes.selectContent}
                        value={valuesAbs.startJourneyAbs}
                        onChange={(e) => {
                            setValuesAbs({ ...valuesAbs, startJourneyAbs: e.target.value })
                        }}
                    >
                        <option placeholder='AM'>AM</option>
                        <option value="PM">PM</option>
                    </select>
                </div>
            </FormControl>
            <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: '10px' }}>
                <Controls.DatePicker
                    name="endAbs"
                    label="Date fin"
                    value={valuesAbs.endAbs}
                    onChange={handleInputChange}
                    error={checkDateEndEmptyAbs()}
                />
                <div className={classes.selectHours} >
                    <label style={{ fontSize: '14px', }}>AM/PM:</label>
                    <select
                        className={classes.selectContent}
                        value={valuesAbs.endJourneyAbs}
                        onChange={(e) => {
                            setValuesAbs({ ...valuesAbs, endJourneyAbs: e.target.value })
                        }}
                    >
                        <option placeholder='AM'>AM</option>
                        <option value="PM">PM</option>
                    </select>
                </div>
            </FormControl>
            <FormControl className={classes.absType} >
                <label style={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>Type d'absence :</label>
                <select
                    className={classes.selectContent}
                    value={valuesAbs.absType}
                    onChange={(e) => {
                        setValuesAbs({ ...valuesAbs, absType: e.target.value })
                    }}
                >
                    <option placeholder='Maladie'>Maladie</option>
                    <option value="Autre">Autre</option>
                </select>
            </FormControl>
            <FormControl style={{ width: '100%' }}>
                <TextField
                    className={classes.root}
                    type="number"
                    label="Nombre de jour"
                    value={valuesAbs.nbrDayAbs}
                    variant="outlined"
                    inputProps={{
                        step: "0.5"
                    }}
                    onChange={(e) => additionDayAbs(e)}
                />
                <span style={{ position: 'absolute', bottom: '-20px', color: 'red' }}>{checkNbrDayAbsEmptyAbs()}</span>
            </FormControl>
            {
                valuesAbs.absType === 'Autre' &&
                <FormControl style={{ width: '100%', marginTop: '20px' }}>
                    <Controls.Textarea
                        sx={{ width: '100%', marginBottom: '10px' }}
                        name="anotherReasonAbs"
                        label="Saisissez un autre motif"
                        value={valuesAbs.anotherReasonAbs}
                        onChange={handleInputChange}
                        error={checkAnotherReasonEmptyAbs()}
                    />
                </FormControl>
            }
        </form>
    )
}

function Abs() {
    const { haveAccessTo } = useAuth();
    const classes = useStyles();
    const initialeValueAbs = {
        employeeId: null,
        endAbs: new Date(),
        endJourneyAbs: 'AM',
        startAbs: new Date(),
        startJourneyAbs: 'AM',
        absType: 'Maladie',
        anotherReasonAbs: '',
        nbrDayAbs: 0,
    }
    const [rows, setRows] = useState([]);
    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [valuesAbs, setValuesAbs] = useState(initialeValueAbs);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [openNotif, setOpenNotif] = useState(false);
    const [messageNotif, setMessageNotif] = useState('');
    const [options, setOptions] = useState([]);
    const [severityPerso, setSeverityPerso] = useState("");
    const [isClickExport, setIsClickExport] = useState(false);

    const monthStartEndDate = getBeginEndDateOnMonth(new Date());

    const [filterDates, setFilterDates] = useState({
        dateStart: monthStartEndDate.start,
        dateEnd: monthStartEndDate.end
    })

    const calculDay = ( start, end, startJourney, endJourney) => {
        if (
            start !== '' &&
            end !== ''
            ) {
            if (start === end && startJourney === 'AM' && endJourney === 'AM') {
            // nbDay += 0.5
            return 0.5;
            }
            if (start === end && startJourney === 'AM' && endJourney === 'PM') {
            // nbDay += 1
            return 1;
            }
            if (start === end && startJourney === 'PM' && endJourney === 'PM') {
            // nbDay += 0.5
            return 0.5;
            }
            if (start !== end && startJourney === 'AM' && endJourney === 'AM') {
            const d1 = new Date(start);
            const d2 = new Date(end);
            const oneDay = 24 * 60 * 60 * 1000;
            const date1InMillis = d1.getTime();
            const date2InMillis = d2.getTime();
            const days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay);
            const nbD = days + 1;
            // nbDay += nbD - 0.5;
            return nbD - 0.5;
            }
            if (start !== end && startJourney === 'AM' && endJourney === 'PM') {
            const d1 = new Date(start);
            const d2 = new Date(end);
            const oneDay = 24 * 60 * 60 * 1000;
            const date1InMillis = d1.getTime();
            const date2InMillis = d2.getTime();
            const days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay);
            const nbD = days + 1;
            // nbDay += nbD;
            // nbDay += nbD;
            return nbD;
            }
            if (start !== end && startJourney === 'PM' && endJourney === 'AM') {
            const d1 = new Date(start);
            const d2 = new Date(end);
            const oneDay = 24 * 60 * 60 * 1000;
            const date1InMillis = d1.getTime();
            const date2InMillis = d2.getTime();
            const days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay);
            const nbD = days + 1;
            // nbDay += nbD - 1;
            return nbD - 1;
            }
            if (start !== end && startJourney === 'PM' && endJourney === 'PM') {
            const d1 = new Date(start);
            const d2 = new Date(end);
            const oneDay = 24 * 60 * 60 * 1000;
            const date1InMillis = d1.getTime();
            const date2InMillis = d2.getTime();
            const days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay);
            const nbD = days + 1;
            // nbDay += nbD - 0.5;
            return nbD - 0.5;
            }
        }
    }

    useEffect(() => {
        async function getEmpoyee() {
            const response = await AbsService.getAllEmployee();
            if(response?.status?.code === 200) {
                setOptions(response?.data);
            }
        }
        getEmpoyee();
      }, []);

    useEffect(() => {
        setValuesAbs({ ...valuesAbs, nbrDayAbs: calculDay(valuesAbs.startAbs, valuesAbs.endAbs, valuesAbs.startJourneyAbs, valuesAbs.endJourneyAbs) });
        // eslint-disable-next-line
    }, [valuesAbs.startAbs, valuesAbs.endAbs, valuesAbs.startJourneyAbs, valuesAbs.endJourneyAbs]);

    const getListAbs = async () => {
        const response = await AbsService.getAllAbs(filterDates)
        const newRows = []
        if (response?.status?.code === 200) {
            response?.data?.forEach(el => {
                newRows.push({
                  id: el.id,
                  matricule: el.matricule,
                  absType: el.absType,
                  startAbs: moment(el.startAbs).format('DD MMMM YYYY') + ' ' + el.startJourneyAbs,
                  endAbs: moment(el.endAbs).format('DD MMMM YYYY') + ' ' + el.endJourneyAbs,
                  nbrDayAbs: el.nbrDayAbs,
                });
            });
        }
        setRows(newRows);
    }

    useEffect(() => {
        getListAbs();
    // eslint-disable-next-line
    }, [filterDates.dateStart, filterDates.dateEnd])

    const closeModal = () => {
        setOpenAddEdit(false);
        setValuesAbs(initialeValueAbs);
        setIsSubmitted(false);
    }

    const handleCloseNotif = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageNotif("");
        setOpenNotif(false);
        setSeverityPerso("");
    };

    const columns = [
        {
            field: 'matricule',
            headerName: 'Matricule',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'absType',
            headerName: "Type d'absence",
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'startAbs',
            headerName: 'Date de début',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'endAbs',
            headerName: 'Date fin',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'nbrDayAbs',
            headerName: 'Nombre de jour(s)',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
    ]

    const onSaveNewAbs = async () => {
        if (haveAccessTo(CURRENT_PAGE, 'add')) {
          setIsSubmitted(true);
          if (
            valuesAbs.employeeId &&
            valuesAbs.startAbs &&
            valuesAbs.endAbs &&
            valuesAbs.absType &&
            (valuesAbs.nbrDayAbs && valuesAbs.nbrDayAbs > 0)
          ) {
            const response = await AbsService.addAbs(valuesAbs);
            if(response?.status?.code === 200) {
                let dataResponse = response.data;
                const addData = {
                    id: dataResponse.id,
                    matricule: dataResponse.matricule,
                    absType: dataResponse.absType,
                    startAbs: moment(dataResponse.startAbs).format('DD MMMM YYYY') + ' ' + dataResponse.startJourneyAbs,
                    endAbs: moment(dataResponse.endAbs).format('DD MMMM YYYY') + ' ' + dataResponse.endJourneyAbs,
                    nbrDayAbs: dataResponse.nbrDayAbs,
                }
                setRows([...rows, addData])
                closeModal();
                setSeverityPerso("success");
                setOpenNotif(true);
                setMessageNotif("Absence ajouter avec succès.");
            } else {
                setSeverityPerso("error");
                setOpenNotif(true);
                setMessageNotif(response.status.message);
            }
          }
        }
    };

    const onExportExcel = async () => {
        setIsClickExport(true);
        AbsService.generateExcel(filterDates).then((res) => {
          setIsClickExport(false);
          if (res?.status?.code === 200) {
              let a = document.createElement('a');
              a.href = 'data:application/octet-stream;base64,' + res.data.content;
              a.download = `absences.xlsx`;
              a.click();
          }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFilterDates({
            ...filterDates,
            [name]: value
        })
    }

    return (
        <>
            <Notification
                severity={severityPerso}
                openNotif={openNotif}
                handleClose={handleCloseNotif}
                message={messageNotif}
            />
            <div style={{ padding: 20 }}>
                <div className={classes.headContent}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.iconTitle}>
                            <GiStabbedNote style={{ fontSize: '1.1rem' }} />
                        </div>
                        <div>
                            <h4>Absences</h4>
                            <ButtonComponent style={{marginLeft: '20px', textTransform: 'none'}} text="Nouveau" variant="contained" onClick={() => setOpenAddEdit(true)}/>
                        </div>
                    </div>
                    <div>
                        <form>
                            <FormControl style={{ width: '200px' }}>
                                <Controls.DatePicker
                                    name="dateStart"
                                    label="Début"
                                    value={filterDates.dateStart}
                                    onChange={handleInputChange}
                                />  
                            </FormControl>
                            &nbsp;
                            <FormControl style={{ width: '200px' }}>
                                <Controls.DatePicker
                                    name="dateEnd"
                                    label="Fin"
                                    value={filterDates.dateEnd}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </form>
                    </div>
                    <div>
                        <Button
                            disabled={isClickExport}
                            style={{ textTransform: 'capitalize', opacity: isClickExport ? '0.5' : '1' }}
                            variant="text"
                            size="small"
                            onClick={onExportExcel}
                        >
                            {
                                isClickExport &&
                                <CircularProgress className={classes.progress} color="inherit" />
                            }
                            <SvgExcel />
                        </Button>
                    </div>
                </div>
                <Table columns={columns} rows={rows}/>
            </div>
            <ModalComponent
                openModal={openAddEdit}
                close={() => closeModal()}
                title={'Ajout'}
                submit={() => onSaveNewAbs()}
                actionTitle={'Enregistrer'}
                modalContentBody={<ModalContentBody options={options} setValuesAbs={setValuesAbs} valuesAbs={valuesAbs} isSubmitted={isSubmitted} />}
            />
        </>
    )
}

export default Abs;