import React, { useEffect, useState } from 'react';
import UserService from './services/User.jsx';
import Table from '../../components/table/index.jsx';
import Button from '@mui/material/Button';
import ModalComponent from '../../components/modalComponent/index.jsx';
import { useAuth } from '../../applications/hooks/UseAuth';
import Notification from '../../components/notificationComponent/index.jsx';
import Controls from '../../components/Entry/Controls.jsx';
import FormControl from '@material-ui/core/FormControl';
import Tooltips from '../../components/tooltips/index.jsx';
import { MdDeleteOutline } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import Zoom from '@material-ui/core/Zoom';
import useStyles from './style.jsx';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ButtonComponent from '../../components/Entry/Button.jsx';

const CURRENT_PAGE = 'users';

function ModalContentBody(props) {
    const {editId, valuesUser, setValuesUser, isSubmitted, options} = props;

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setValuesUser({
            ...valuesUser,
            [name]: value
        })
    }

    const checkEmptyRole = () => {
        if (isSubmitted && !valuesUser.role) {
          return 'Le champ rôle est requis.'
        }
    }
    
    const checkEmptyLastName = () => {
        if (isSubmitted && !valuesUser.lastname) {
            return 'Le champ nom est requis.'
        }
    }

    const checkEmptyfirstName = () => {
        if (isSubmitted && !valuesUser.firstname) {
            return 'Le champ prénom est requis.'
        }
    }

    const checkEmptyEmail = () => {
        if (isSubmitted && !valuesUser.email) {
            return 'Le champ email est requis.'
        }
    }

    const checkEmptyPassword = () => {
        if (isSubmitted && !valuesUser.password) {
            return 'Le champ mots de passe est requis.'
        }
    }

    useEffect(() => {
        if(editId) {
            UserService.getSingleUser(editId).then((res) => {
                if(res?.status?.code === 200) {
                    const dataEdit = {
                        lastname: res.data.lastname,
                        firstname: res.data.firstname,
                        email: res.data.email,
                        password: res.data.password,
                        role: res.data.roleId,
                    }
                    setValuesUser(dataEdit)
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <form>
            <FormControl style={{ width: '100%', marginTop: '20px' }}>
                <Controls.Input
                    sx={{ width: '100%', marginBottom: '10px' }}
                    name="lastname"
                    label="Nom"
                    value={valuesUser.lastname}
                    onChange={handleInputChange}
                    error={checkEmptyLastName()}
                />
            </FormControl>
            <FormControl style={{ width: '100%', marginTop: '20px' }}>
                <Controls.Input
                    sx={{ width: '100%', marginBottom: '10px' }}
                    name="firstname"
                    label="Prénom"
                    value={valuesUser.firstname}
                    onChange={handleInputChange}
                    error={checkEmptyfirstName()}
                />
            </FormControl>
            <FormControl style={{ width: '100%', marginTop: '20px' }}>
                <Controls.Input
                    sx={{ width: '100%', marginBottom: '10px' }}
                    name="email"
                    label="Email"
                    value={valuesUser.email}
                    onChange={handleInputChange}
                    error={checkEmptyEmail()}
                />
            </FormControl>
            <FormControl style={{ width: '100%', marginTop: '20px' }}>
                <Controls.Input
                    sx={{ width: '100%', marginBottom: '10px' }}
                    name="password"
                    label="Mots de passe"
                    value={valuesUser.password}
                    onChange={handleInputChange}
                    error={checkEmptyPassword()}
                />
            </FormControl>
            <FormControl style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px', marginBottom: '30px'}} >
                <label style={{ marginLeft: '14px', fontWeight: 'bold', fontSize: '14px', color: 'black' }}>Rôle</label>
                <select
                    style={{
                        width: '100%',
                        padding: '8px',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderBottom: '1px solid #ccc'
                    }}
                    value={valuesUser.role}
                    onChange={(e) => {
                        setValuesUser({ ...valuesUser, role: e.target.value });
                    }}
                >
                    <option></option>
                    {options.map((el) => (
                    <option key={el.id} value={el.id}>
                        {el.name}
                    </option>
                    ))}
                </select>
                <span style={{ position: 'absolute', fontSize: '0.75rem', bottom: '-20px', color: '#d32f2f' }}>{checkEmptyRole()}</span>
            </FormControl>
        </form>
    )
}

function User() {
    const { haveAccessTo } = useAuth();
    const classes = useStyles();
    const initialeValueUser = {
        lastname: "",
        firstname: "",
        email: "",
        password: "",
        role: "",
    }
    const [rows, setRows] = useState([]);
    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [valuesUser, setValuesUser] = useState(initialeValueUser);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [openNotif, setOpenNotif] = useState(false);
    const [messageNotif, setMessageNotif] = useState('');
    const [options, setOptions] = useState([]);
    const [severityPerso, setSeverityPerso] = useState("");
    const [editId, setEditId] = useState(null);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [deletedId, setDeletedId] = useState(null);

    useEffect(() => {
        async function getEmpoyee() {
            const response = await UserService.getAllRole();
            if(response?.status?.code === 200) {
                setOptions(response.data);
            }
        }
        getEmpoyee();
      }, []);

    const getListEmploye = async () => {
        const response = await UserService.getAllUser()
        const newRows = []
        if (response?.status?.code === 200) {
            response?.data?.forEach(el => {
                newRows.push({
                  id: el.id,
                  lastname: el.lastname,
                  firstname: el.firstname,
                  email: el.email,
                  role: el.roles,
                });
            });
        }
        setRows(newRows);
    }

    useEffect(() => {
        getListEmploye();
    }, [])

    const closeModal = () => {
        setOpenAddEdit(false);
        setValuesUser(initialeValueUser);
        setIsSubmitted(false);
        setEditId(null);
    }

    const handleCloseNotif = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageNotif("");
        setOpenNotif(false);
        setSeverityPerso("");
    };

    const handleEdit = (data) => () => {
        setEditId(data.id);
        setOpenAddEdit(true);
    }

    const columns = [
        {
            field: 'lastname',
            headerName: 'Nom',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'firstname',
            headerName: 'Prénoms',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'email',
            headerName: 'Email',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'role',
            headerName: 'Rôle',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'action',
            type: 'actions',
            headerName: 'Action',
            align: 'center',
            headerAlign: 'center',
            width: 150,
            getActions: (params) => {
                const handleDelete = async (id) => {
                    setOpenDeleteConfirm(true);
                    setDeletedId(id);
                }

                return [
                    <>
                        <Tooltips TransitionComponent={Zoom} title="Supprimer">
                            <Button
                                // className="event-delete-btn contentBtnStyle forAllBtn"
                                // disabled={!haveAccessTo(CURRENT_PAGE, 'delete')}
                                onClick={() => { handleDelete(params.id) }}
                            >
                                <MdDeleteOutline className='actionIconStyle' />
                            </Button>
                        </Tooltips>
                        <Tooltips TransitionComponent={Zoom} title="Modifier">
                            <Button
                                // className="event-edit-btn contentBtnStyle forAllBtn"
                                // disabled={!haveAccessTo(CURRENT_PAGE, 'edit')}
                                onClick={handleEdit(params)}
                            >
                                <FaRegEdit className='actionIconStyle'/>
                            </Button>
                        </Tooltips>
                    </>
                ]
            }
        },
    ]

    const onSaveNewUser = async () => {
        setIsSubmitted(true);
        if (haveAccessTo(CURRENT_PAGE, 'add') && !editId) {
          if (
            valuesUser.lastname &&
            valuesUser.firstname &&
            valuesUser.email &&
            valuesUser.password &&
            valuesUser.role
          ) {
            const response = await UserService.addUser(valuesUser);
            if(response?.status?.code === 200) {
                let dataResponse = response.data;
                const addData = {
                  id: dataResponse.id,
                  lastname: dataResponse.lastname,
                  firstname: dataResponse.firstname,
                  email: dataResponse.email,
                  role: dataResponse.roleId,
                }
                setRows([...rows, addData])
                closeModal();
                setSeverityPerso("success");
                setOpenNotif(true);
                setMessageNotif("User ajouter avec succès.");
            } else {
                setSeverityPerso("error");
                setOpenNotif(true);
                setMessageNotif(response.status.message);
            }
          }
        }
        if (haveAccessTo(CURRENT_PAGE, 'edit') && editId) {
            if (
              valuesUser.lastname &&
              valuesUser.firstname &&
              valuesUser.email &&
              valuesUser.password &&
              valuesUser.role
            ) {
              const response = await UserService.editUser(editId, valuesUser);
              if(response?.status?.code === 200) {
                  let dataResponse = response.data;
                  setRows(
                    rows.map((row) => {
                        const updateData = {
                            ...row,
                            lastname: dataResponse.lastname,
                            firstname: dataResponse.firstname,
                            email: dataResponse.email,
                            role: dataResponse.roleId,
                        }
                      return row.id === response.data.id ? updateData : row;
                    })
                  );
                  closeModal();
                  setSeverityPerso("success");
                  setOpenNotif(true);
                  setMessageNotif("User modifier avec succès.");
              } else {
                  setSeverityPerso("error");
                  setOpenNotif(true);
                  setMessageNotif(response.status.message);
              }
            }
          }
    };

    const confirmDelete = async () => {
        const response = await UserService.deleteUser(deletedId);
        if(response?.status?.code === 200) {
            setSeverityPerso("success");
            setDeletedId(null);
            setMessageNotif(`Utilisateur supprimer avec succès.`);
            setRows(rows.filter((el) => el.id !== deletedId));
            setOpenDeleteConfirm(false);
            setOpenNotif(true);
        }
    }

    return (
        <>
            <Notification
                severity={severityPerso}
                openNotif={openNotif}
                handleClose={handleCloseNotif}
                message={messageNotif}
            />
            <div style={{ padding: 20 }}>
                <div className={classes.headContent}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.iconTitle}>
                            <ManageAccountsOutlinedIcon style={{ fontSize: '1.1rem' }} />
                        </div>
                        <div>
                            <h4>Utilisateurs</h4>
                            <ButtonComponent style={{marginLeft: '20px', textTransform: 'none'}} text="Nouveau" variant="contained" onClick={() => setOpenAddEdit(true)}/>
                        </div>
                    </div>
                </div>
                <Table columns={columns} rows={rows}/>
            </div>
            <ModalComponent
                openModal={openAddEdit}
                close={() => closeModal()}
                title={'Ajout'}
                submit={() => onSaveNewUser()}
                actionTitle={'Enregistrer'}
                modalContentBody={<ModalContentBody editId={editId} options={options} setValuesUser={setValuesUser} valuesUser={valuesUser} isSubmitted={isSubmitted} />}
            />
            <ModalComponent
                openModal={openDeleteConfirm}
                close={() => {
                    setOpenDeleteConfirm(false)
                    setDeletedId(null);
                }}
                title={'Suppression'}
                submit={() => confirmDelete()}
                actionTitle={'Confirmer'}
                modalContentBody={'Est-ce que vous voulez vraiment supprimer cet utilisateur ?'}
            />
        </>
    )
}

export default User;