import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DASHBOARD_SIDEBAR_LINKS } from '../../routes/menu'
import { Grid } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TransfertList from './TransfertList'
import RoleService from './services/Role'
import Button from '@mui/material/Button'
import { IoIosSave } from "react-icons/io"

const useStyles = makeStyles((theme) => ({
    pageContent: {
        textAlign: 'right',
        paddingBottom: '1rem'
    },
}))

const Link = (props) => {
    const { linkKey, link, submenu, permLoaded, permissions, handlePermissions } = props
    // const [expanded, setExpanded] = React.useState(false)

    // const handleChange = (panel) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false)
    // }

    let keyString = [link.key]
    linkKey && keyString.unshift(linkKey)

    return (
        <Accordion
            // expanded={submenu || expanded === link.key}
            // onChange={handleChange(link.key)}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                <Typography sx={{ width: '33%', flexShrink: 0 }}>{link.label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid item>
                    {submenu
                        ? submenu.map((link) => (
                              <Grid key={link.key} item>
                                  <Link
                                      permissions={permissions}
                                      permLoaded={permLoaded}
                                      linkKey={keyString}
                                      handlePermissions={handlePermissions}
                                      link={link}
                                  />
                              </Grid>
                          ))
                        : link.permissions && (
                              <TransfertList
                                  permissions={permissions}
                                  permLoaded={permLoaded}
                                  linkKey={keyString.join('_')}
                                  handlePermissions={handlePermissions}
                                  allChoices={link.permissions}
                              />
                          )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

function SetPermissionPage(props) {
    const classes = useStyles()
    const { roleId, toggleDrawer, setSeverityPerso, setOpenNotif, setMessageNotif } = props
    const [permissions, setPermissions] = useState({})
    const [onLoad, setOnLoad] = useState(true)
    const [permLoaded, setPermLoaded] = useState(false)

    const handlePermissions = (linkKey, permission) => {
        let changedPermission = permissions
        changedPermission[linkKey] = permission
        setPermissions(changedPermission)
    }

    const savePermissions = () => {
        const permission = RoleService.setPermission(roleId, { permissions: JSON.stringify(permissions) })
        if(permission) {
            setSeverityPerso("success");
            setMessageNotif(`Permissions enregistrées avec succès.`);
            setOpenNotif(true);
        } else {
            setSeverityPerso("error");
            setMessageNotif(`Permissions non enregistrées.`);
            setOpenNotif(true);
        }
        toggleDrawer(false)
    }

    useEffect( () => {
        if (onLoad) {
            RoleService.getPermission(roleId).then(response => {
                return JSON.parse(response?.data?.permissions || '{}')
            }).then(data => {
                setPermissions( data)
                setOnLoad(false)
                setPermLoaded(true)
            })
        }
        // eslint-disable-next-line
    }, [permissions])

    return (
        <>
            <Grid className={classes.pageContent}>
                <Button
                    style={{ textTransform: 'capitalize' }}
                    variant="text"
                    size="small"
                    onClick={savePermissions}
                >
                    <IoIosSave className='actionIconStyle' />
                    Enregister
                </Button>
            </Grid>

            {DASHBOARD_SIDEBAR_LINKS.map((link) => (
                    link.submenu ? (
                        <Link
                            key={link.key}
                            permissions={permissions}
                            permLoaded={permLoaded}
                            handlePermissions={handlePermissions}
                            link={link}
                            submenu={link.submenu}
                        />
                    ) : (
                        <Link
                            key={link.key}
                            permissions={permissions}
                            permLoaded={permLoaded}
                            handlePermissions={handlePermissions}
                            link={link}
                        />
                    )
            ))}
        </>
    )
}

export default SetPermissionPage
