import React, { useEffect, useState } from 'react';
import HolydayService from './service/Holyday.jsx';
import Table from '../../components/table/index.jsx';
import moment from 'moment';
import Button from '@mui/material/Button';
import ModalComponent from '../../components/modalComponent/index.jsx';
import { useAuth } from '../../applications/hooks/UseAuth';
import Notification from '../../components/notificationComponent/index.jsx';
import Controls from '../../components/Entry/Controls.jsx';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '../../components/Autocomplete';
import { CircularProgress } from '@material-ui/core';
import useStyles from './style.jsx';
import ButtonComponent from '../../components/Entry/Button.jsx';
import { GiNotebook } from "react-icons/gi";
import SvgExcel from '../../components/Entry/svgExcel.jsx';
import { FaRegFilePdf } from "react-icons/fa";
import Zoom from '@material-ui/core/Zoom';
import Tooltips from '../../components/tooltips/index.jsx';

const CURRENT_PAGE = 'holyday';

function getBeginEndDateOnMonth(currentDate) {
    let date = new Date(currentDate);
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    
    let start = firstDay;
    let end = lastDay;
    return {
      start,
      end
    }
}

function ModalContentBody(props) {
    const {valuesHolyday, setValuesHolyday, isSubmitted, options} = props;
    const classes = useStyles();
    const [fullName, setFullName] = useState({
        lastName: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setValuesHolyday({
            ...valuesHolyday,
            [name]: value
        })
    }
    
    const additionDayConge = (e) => {
        let nbrDayEdit = e.target.value;
        let date = new Date(valuesHolyday.start);
        let endDate;
        if(valuesHolyday.startJourney === 'AM' && nbrDayEdit % 1 === 0) {
            endDate = date.setDate(date.getDate() + parseFloat(nbrDayEdit) - 1);
        } else if (valuesHolyday.startJourney === 'PM' && nbrDayEdit % 1 === 0) {
            endDate = date.setDate(date.getDate() + parseFloat(nbrDayEdit));
        } else if (valuesHolyday.startJourney === 'AM' && nbrDayEdit > 1 && nbrDayEdit % 1 !== 0) {
            endDate = date.setDate(date.getDate() + parseFloat(nbrDayEdit));
        } else if (valuesHolyday.startJourney === 'PM' && nbrDayEdit > 1 && nbrDayEdit % 1 !== 0) {
            endDate = date.setDate(date.getDate() + parseFloat(nbrDayEdit));
        }
    
        let dateStart = date;
        let dateEnd = new Date(endDate);
        // eslint-disable-next-line
        if (valuesHolyday.startJourney === 'AM' && nbrDayEdit == 0.5) {
            setValuesHolyday({
            ...valuesHolyday,
            nbrDayConge: parseFloat(nbrDayEdit),
            end: dateStart,
            endJourney: 'AM'
            });
        }
        // eslint-disable-next-line
        if (valuesHolyday.startJourney === 'AM' && nbrDayEdit == 1) {
            setValuesHolyday({
            ...valuesHolyday,
            nbrDayConge: parseFloat(nbrDayEdit),
            end: dateStart,
            endJourney: 'PM'
            });
        }
        if (valuesHolyday.startJourney === 'AM' && nbrDayEdit > 1 && nbrDayEdit % 1 === 0) {
            setValuesHolyday({
            ...valuesHolyday,
            nbrDayConge: parseFloat(nbrDayEdit),
            end: dateEnd,
            endJourney: 'PM'
            });
        }
    
        if (valuesHolyday.startJourney === 'AM' && nbrDayEdit > 1 && nbrDayEdit % 1 !== 0) {
            setValuesHolyday({
            ...valuesHolyday,
            nbrDayConge: parseFloat(nbrDayEdit),
            end: dateEnd,
            endJourney: 'AM'
            });
        }
    
        if (valuesHolyday.startJourney === 'PM' && nbrDayEdit > 1 && nbrDayEdit % 1 !== 0) {
            setValuesHolyday({
            ...valuesHolyday,
            nbrDayConge: parseFloat(nbrDayEdit),
            end: dateEnd,
            endJourney: 'PM'
            });
        }
        // eslint-disable-next-line
        if (valuesHolyday.startJourney === 'PM' && nbrDayEdit == 0.5) {
            setValuesHolyday({
            ...valuesHolyday,
            nbrDayConge: parseFloat(nbrDayEdit),
            end: dateStart,
            endJourney: 'PM'
            });
        }
        if (valuesHolyday.startJourney === 'PM' && nbrDayEdit % 1 === 0) {
            setValuesHolyday({
            ...valuesHolyday,
            nbrDayConge: parseFloat(nbrDayEdit),
            end: dateEnd,
            endJourney: 'AM'
            });
        }
    }

    const checkEmployeeNumberEmptyConge = () => {
        if (isSubmitted && !valuesHolyday.employeeId) {
          return 'Le champ matricule est requis.'
        }
    }
    
    const checkDateStartEmptyConge = () => {
        if (isSubmitted && !valuesHolyday.start) {
            return 'Le champ date début est requis.'
        }
    }
    
    const checkDateEndEmptyConge = () => {
        if (isSubmitted && !valuesHolyday.end) {
            return 'Le champ Date fin est requis.'
        }
    }

    const checkAnotherReasonEmptyConge = () => {
        if (isSubmitted && !valuesHolyday.anotherReason) {
            return 'Ce champ est requis.'
        }
    }

    const checkNbrDayCongeEmptyConge = () => {
         if (isSubmitted && valuesHolyday.nbrDayConge <= 0) {
            return 'Le nombre de jour doit être suppérieur à zéro'
        }
    }

    const onTagsChangeConge = (event, values) => {
        if (values !== null) {
            setValuesHolyday({ ...valuesHolyday, employeeId: values.id });
            setFullName({ ...fullName, lastName: values.name });
        } else {
            setValuesHolyday({ ...valuesHolyday, employeeId: null });
            setFullName('');
        }
    }

    return (
        <form>
            <FormControl style={{ width: '100%', marginBottom: '10px', position: 'relative' }}>
                <Autocomplete
                    label={"N° Matricule"}
                    editMat={null}
                    onTagsChange={onTagsChangeConge}
                    options={options}
                    lastName={fullName.lastName}
                />
                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-5px' }}>{checkEmployeeNumberEmptyConge()}</span>
            </FormControl>
            <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: '10px' }}>
                <Controls.DatePicker
                    name="start"
                    label="Date de début"
                    value={valuesHolyday.start}
                    onChange={handleInputChange}
                    error={checkDateStartEmptyConge()}
                />
                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-5px' }}>{checkDateStartEmptyConge()}</span>
                <div className={classes.selectHours} >
                    <label style={{ fontSize: '14px', }}>AM/PM:</label>
                    <select
                        className={classes.selectContent}
                        value={valuesHolyday.startJourney}
                        onChange={(e) => {
                            setValuesHolyday({ ...valuesHolyday, startJourney: e.target.value })
                        }}
                    >
                        <option placeholder='AM'>AM</option>
                        <option value="PM">PM</option>
                    </select>
                </div>
            </FormControl>
            <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: '10px' }}>
                <Controls.DatePicker
                    name="end"
                    label="Date fin"
                    value={valuesHolyday.end}
                    onChange={handleInputChange}
                    error={checkDateEndEmptyConge()}
                />
                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-5px' }}>{checkDateEndEmptyConge()}</span>
                <div className={classes.selectHours} >
                    <label style={{ fontSize: '14px', }}>AM/PM:</label>
                    <select
                        className={classes.selectContent}
                        value={valuesHolyday.endJourney}
                        onChange={(e) => {
                            setValuesHolyday({ ...valuesHolyday, endJourney: e.target.value })
                        }}
                    >
                        <option placeholder='AM'>AM</option>
                        <option value="PM">PM</option>
                    </select>
                </div>
            </FormControl>
            <FormControl className={classes.congeType} >
                <label style={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>Type de congé :</label>
                <select
                    className={classes.selectContent}
                    value={valuesHolyday.congeType}
                    onChange={(e) => {
                        setValuesHolyday({ ...valuesHolyday, congeType: e.target.value })
                    }}
                >
                    <option placeholder='Maladie'>Maladie</option>
                    <option value="Autre">Autre</option>
                </select>
            </FormControl>
            <FormControl style={{ width: '100%' }}>
                <TextField
                    className={classes.root}
                    type="number"
                    label="Nombre de jour"
                    value={valuesHolyday.nbrDayConge}
                    variant="outlined"
                    inputProps={{
                        step: "0.5"
                    }}
                    onChange={(e) => additionDayConge(e)}
                />
                <span style={{ position: 'absolute', fontSize: '0.75rem', color: '#d32f2f', bottom: '-5px' }}>{checkNbrDayCongeEmptyConge()}</span>
            </FormControl>
            {
                valuesHolyday.congeType === 'Autre' &&
                <FormControl style={{ width: '100%', marginTop: '20px' }}>
                    <Controls.Textarea
                        sx={{ width: '100%', marginBottom: '10px' }}
                        name="anotherReason"
                        label="Saisissez un autre motif"
                        value={valuesHolyday.anotherReason}
                        onChange={handleInputChange}
                        error={checkAnotherReasonEmptyConge()}
                    />
                </FormControl>
            }
        </form>
    )
}

function Holyday() {
    const { haveAccessTo } = useAuth();
    const classes = useStyles();
    const initialeValueHolyday = {
        employeeId: null,
        end: new Date(),
        endJourney: 'AM',
        start: new Date(),
        startJourney: 'AM',
        congeType: 'Maladie',
        anotherReason: '',
        nbrDayConge: 0,
    }
    const [rows, setRows] = useState([]);
    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [valuesHolyday, setValuesHolyday] = useState(initialeValueHolyday);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [openNotif, setOpenNotif] = useState(false);
    const [messageNotif, setMessageNotif] = useState('');
    const [options, setOptions] = useState([]);
    const [severityPerso, setSeverityPerso] = useState("");
    const [isClickExport, setIsClickExport] = useState(false);
    const [loadingRowId, setLoadingRowId] = useState(null);

    const monthStartEndDate = getBeginEndDateOnMonth(new Date());

    const [filterDates, setFilterDates] = useState({
        dateStart: monthStartEndDate.start,
        dateEnd: monthStartEndDate.end
    })

    const calculDay = ( start, end, startJourney, endJourney) => {
        if (
            start !== '' &&
            end !== ''
            ) {
            if (start === end && startJourney === 'AM' && endJourney === 'AM') {
            // nbDay += 0.5
            return 0.5;
            }
            if (start === end && startJourney === 'AM' && endJourney === 'PM') {
            // nbDay += 1
            return 1;
            }
            if (start === end && startJourney === 'PM' && endJourney === 'PM') {
            // nbDay += 0.5
            return 0.5;
            }
            if (start !== end && startJourney === 'AM' && endJourney === 'AM') {
            const d1 = new Date(start);
            const d2 = new Date(end);
            const oneDay = 24 * 60 * 60 * 1000;
            const date1InMillis = d1.getTime();
            const date2InMillis = d2.getTime();
            const days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay);
            const nbD = days + 1;
            // nbDay += nbD - 0.5;
            return nbD - 0.5;
            }
            if (start !== end && startJourney === 'AM' && endJourney === 'PM') {
            const d1 = new Date(start);
            const d2 = new Date(end);
            const oneDay = 24 * 60 * 60 * 1000;
            const date1InMillis = d1.getTime();
            const date2InMillis = d2.getTime();
            const days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay);
            const nbD = days + 1;
            // nbDay += nbD;
            // nbDay += nbD;
            return nbD;
            }
            if (start !== end && startJourney === 'PM' && endJourney === 'AM') {
            const d1 = new Date(start);
            const d2 = new Date(end);
            const oneDay = 24 * 60 * 60 * 1000;
            const date1InMillis = d1.getTime();
            const date2InMillis = d2.getTime();
            const days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay);
            const nbD = days + 1;
            // nbDay += nbD - 1;
            return nbD - 1;
            }
            if (start !== end && startJourney === 'PM' && endJourney === 'PM') {
            const d1 = new Date(start);
            const d2 = new Date(end);
            const oneDay = 24 * 60 * 60 * 1000;
            const date1InMillis = d1.getTime();
            const date2InMillis = d2.getTime();
            const days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay);
            const nbD = days + 1;
            // nbDay += nbD - 0.5;
            return nbD - 0.5;
            }
        }
    }

    useEffect(() => {
        async function getEmpoyee() {
            const response = await HolydayService.getAllEmployee();
            if(response?.status?.code === 200) {
                setOptions(response.data);
            }
        }
        getEmpoyee();
      }, []);

    useEffect(() => {
        setValuesHolyday({ ...valuesHolyday, nbrDayConge: calculDay(valuesHolyday.start, valuesHolyday.end, valuesHolyday.startJourney, valuesHolyday.endJourney) });
        // eslint-disable-next-line
    }, [valuesHolyday.start, valuesHolyday.end, valuesHolyday.startJourney, valuesHolyday.endJourney]);

    const getListHolyday = async () => {
        const response = await HolydayService.getAllHolyday(filterDates)
        const newRows = []
        if (response?.status?.code === 200) {
            response?.data?.forEach(el => {
                newRows.push({
                  id: el.id,
                  matricule: el.matricule,
                  congeType: el.congeType,
                  start: moment(el.start).format('DD MMMM YYYY') + ' ' + el.startJourney,
                  end: moment(el.end).format('DD MMMM YYYY') + ' ' + el.endJourney,
                  nbrDayConge: el.nbrDayConge,
                });
            });
        }
        setRows(newRows);
    }

    useEffect(() => {
        getListHolyday();
        // eslint-disable-next-line
    }, [filterDates.dateStart, filterDates.dateEnd])

    const closeModal = () => {
        setOpenAddEdit(false);
        setValuesHolyday(initialeValueHolyday);
        setIsSubmitted(false);
    }

    const handleCloseNotif = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageNotif("");
        setOpenNotif(false);
        setSeverityPerso("");
    };

    const columns = [
        {
            field: 'matricule',
            headerName: 'Matricule',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'congeType',
            headerName: 'Type de congé',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'start',
            headerName: 'Date de début',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'end',
            headerName: 'Date fin',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'nbrDayConge',
            headerName: 'Nombre de jour(s)',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'action',
            type: 'actions',
            headerName: 'Action',
            align: 'center',
            headerAlign: 'center',
            width: 100,
            renderCell: (params) => {
                const downloadPdf = async (id) => {
                    setLoadingRowId(id);
                    HolydayService.generatePdf(id).then((res) => {
                        if (res?.status?.code === 200) {
                            let a = document.createElement('a');
                            a.href = 'data:application/octet-stream;base64,' + res.data.content;
                            a.download = `Congé-${res.data.matricule}.pdf`;
                            a.click();
                            setLoadingRowId(null);
                        }
                    })
                }
                return (
                    <Tooltips TransitionComponent={Zoom} title="Télécharger (.pdf)">
                        <div>
                            <Button
                                // className="event-delete-btn contentBtnStyle forAllBtn"
                                // disabled={!haveAccessTo(CURRENT_PAGE, 'delete')}
                                disabled={loadingRowId === params.id}
                                onClick={() => {
                                    downloadPdf(params.id);
                                }}
                            >
                                {
                                    loadingRowId === params.id ?
                                    <CircularProgress className={classes.progressPdf}/> :
                                    <FaRegFilePdf />
                                }
                            </Button>
                        </div>
                    </Tooltips>
                )
            }
        },
    ]

    const onSaveNewConge = async () => {
        if (haveAccessTo(CURRENT_PAGE, 'add')) {
          setIsSubmitted(true);
          if (
            valuesHolyday.employeeId &&
            valuesHolyday.start &&
            valuesHolyday.end &&
            valuesHolyday.congeType &&
            (valuesHolyday.nbrDayConge && valuesHolyday.nbrDayConge > 0)
          ) {
            const response = await HolydayService.addHolyday(valuesHolyday);
            if(response?.status?.code === 200) {
                let dataResponse = response.data;
                const addData = {
                    id: dataResponse.id,
                    matricule: dataResponse.matricule,
                    congeType: dataResponse.congeType,
                    start: moment(dataResponse.start).format('DD MMMM YYYY') + ' ' + dataResponse.startJourney,
                    end: moment(dataResponse.end).format('DD MMMM YYYY') + ' ' + dataResponse.endJourney,
                    nbrDayConge: dataResponse.nbrDayConge,
                }
                setRows([...rows, addData])
                closeModal();
                setSeverityPerso("success");
                setOpenNotif(true);
                setMessageNotif("Congé ajouter avec succès.");
            } else {
                setSeverityPerso("error");
                setOpenNotif(true);
                setMessageNotif(response.status.message);
            }
          }
        }
    };

    const onExportExcel = async () => {
        setIsClickExport(true);
        HolydayService.generateExcel(filterDates).then((res) => {
          setIsClickExport(false);
          if (res?.status?.code === 200) {
              let a = document.createElement('a');
              a.href = 'data:application/octet-stream;base64,' + res.data.content;
              a.download = `congés.xlsx`;
              a.click();
          }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFilterDates({
            ...filterDates,
            [name]: value
        })
    }

    return (
        <>
            <Notification
                severity={severityPerso}
                openNotif={openNotif}
                handleClose={handleCloseNotif}
                message={messageNotif}
            />
            <div style={{ padding: 20 }}>
                <div className={classes.headContent}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.iconTitle}>
                            <GiNotebook style={{ fontSize: '1.1rem' }} />
                        </div>
                        <div>
                            <h4>Congé</h4>
                            <ButtonComponent style={{marginLeft: '20px', textTransform: 'none'}} text="Nouveau" variant="contained" onClick={() => setOpenAddEdit(true)}/>
                        </div>
                    </div>
                    <div>
                        <form>
                            <FormControl style={{ width: '200px' }}>
                                <Controls.DatePicker
                                    name="dateStart"
                                    label="Début"
                                    value={filterDates.dateStart}
                                    onChange={handleInputChange}
                                />  
                            </FormControl>
                            &nbsp;
                            <FormControl style={{ width: '200px' }}>
                                <Controls.DatePicker
                                    name="dateEnd"
                                    label="Fin"
                                    value={filterDates.dateEnd}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </form>
                    </div>
                    <div>
                        <Button
                            disabled={isClickExport}
                            style={{ textTransform: 'capitalize', opacity: isClickExport ? '0.5' : '1' }}
                            variant="text"
                            size="small"
                            onClick={onExportExcel}
                        >
                            {
                                isClickExport &&
                                <CircularProgress className={classes.progress} color="inherit" />
                            }
                            <SvgExcel />
                        </Button>
                    </div>
                </div>
                <Table columns={columns} rows={rows}/>
            </div>
            <ModalComponent
                openModal={openAddEdit}
                close={() => closeModal()}
                title={'Ajout'}
                submit={() => onSaveNewConge()}
                actionTitle={'Enregistrer'}
                modalContentBody={<ModalContentBody options={options} setValuesHolyday={setValuesHolyday} valuesHolyday={valuesHolyday} isSubmitted={isSubmitted} />}
            />
        </>
    )
}

export default Holyday;