import React, { useEffect, useState } from 'react';
import RoleService from './services/Role.jsx';
import Table from '../../components/table/index.jsx';
import Button from '@mui/material/Button';
import ModalComponent from '../../components/modalComponent/index.jsx';
import { useAuth } from '../../applications/hooks/UseAuth';
import Notification from '../../components/notificationComponent/index.jsx';
import Controls from '../../components/Entry/Controls.jsx';
import FormControl from '@material-ui/core/FormControl';
import Tooltips from '../../components/tooltips/index.jsx';
import { MdDeleteOutline } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import Zoom from '@material-ui/core/Zoom';
import AddModeratorOutlinedIcon from '@mui/icons-material/AddModeratorOutlined';
import DrawerCompenent from '../../components/drawer/index.jsx';
import SetPermissionPage from './SetPermission';
import useStyles from './style.jsx';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import ButtonComponent from '../../components/Entry/Button.jsx';

const CURRENT_PAGE = 'roles';

function ModalContentBody(props) {
    const {editId, valuesRole, setValuesRole, isSubmitted} = props;

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setValuesRole({
            ...valuesRole,
            [name]: value
        })
    }
    
    const checkEmptyName = () => {
        if (isSubmitted && !valuesRole.name) {
            return 'Le champ nom est requis.'
        }
    }

    useEffect(() => {
        if(editId) {
            RoleService.getSingleRole(editId).then((res) => {
                if(res?.status?.code === 200) {
                    const dataEdit = {
                        name: res.data.name,
                    }
                    setValuesRole(dataEdit)
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <form>
            <FormControl style={{ width: '100%', marginTop: '20px' }}>
                <Controls.Input
                    sx={{ width: '100%', marginBottom: '10px' }}
                    name="name"
                    label="Rôle"
                    value={valuesRole.name}
                    onChange={handleInputChange}
                    error={checkEmptyName()}
                />
            </FormControl>
        </form>
    )
}

function Roles() {
    const { haveAccessTo } = useAuth();
    const classes = useStyles();
    const initialeValueRole = {
        name: "",
    }
    const [rows, setRows] = useState([]);
    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [valuesRole, setValuesRole] = useState(initialeValueRole);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [openNotif, setOpenNotif] = useState(false);
    const [messageNotif, setMessageNotif] = useState('');
    const [severityPerso, setSeverityPerso] = useState("");
    const [editId, setEditId] = useState(null);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [deletedId, setDeletedId] = useState(null);
    const [openPermission, setOpenPermission] = useState(false);
    const [idRole, setIdRole] = useState(null);

    const getListRole = async () => {
        const response = await RoleService.getAllRole()
        const newRows = []
        if (response?.status?.code === 200) {
            response?.data?.forEach(el => {
                newRows.push({
                  id: el.id,
                  name: el.name,
                });
            });
        }
        setRows(newRows);
    }

    useEffect(() => {
        getListRole();
    }, [])

    const closeModal = () => {
        setOpenAddEdit(false);
        setValuesRole(initialeValueRole);
        setIsSubmitted(false);
        setEditId(null);
    }

    const handleCloseNotif = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessageNotif("");
        setOpenNotif(false);
        setSeverityPerso("");
    };

    const handleEdit = (data) => () => {
        setEditId(data.id);
        setOpenAddEdit(true);
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Type',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'permissions',
            type: 'actions',
            headerName: 'Permissions',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            getActions: (params) => {
                const handleDelete = async (id) => {
                    setOpenPermission(true);
                    setIdRole(id);
                }

                return [
                    <Tooltips TransitionComponent={Zoom} title="Permissions">
                        <Button onClick={() => { handleDelete(params.id) }}>
                            <AddModeratorOutlinedIcon className='actionIconStyle' />
                        </Button>
                    </Tooltips>
                ]
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Action',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            getActions: (params) => {
                const handleDelete = async (id) => {
                    setOpenDeleteConfirm(true);
                    setDeletedId(id);
                }

                return [
                    <>
                        <Tooltips TransitionComponent={Zoom} title="Supprimer">
                            <Button
                                // className="event-delete-btn contentBtnStyle forAllBtn"
                                // disabled={!haveAccessTo(CURRENT_PAGE, 'delete')}
                                onClick={() => { handleDelete(params.id) }}
                            >
                                <MdDeleteOutline className='actionIconStyle' />
                            </Button>
                        </Tooltips>
                        <Tooltips TransitionComponent={Zoom} title="Modifier">
                            <Button
                                // className="event-edit-btn contentBtnStyle forAllBtn"
                                // disabled={!haveAccessTo(CURRENT_PAGE, 'edit')}
                                onClick={handleEdit(params)}
                            >
                                <FaRegEdit className='actionIconStyle'/>
                            </Button>
                        </Tooltips>
                    </>
                ]
            }
        },
    ]

    const onSaveNewRole = async () => {
        setIsSubmitted(true);
        if (haveAccessTo(CURRENT_PAGE, 'add') && !editId) {
          if (
            valuesRole.name
          ) {
            const response = await RoleService.addRole(valuesRole);
            if(response?.status?.code === 200) {
                let dataResponse = response.data;
                const addData = {
                  id: dataResponse.id,
                  name: dataResponse.name,
                }
                setRows([...rows, addData])
                closeModal();
                setSeverityPerso("success");
                setOpenNotif(true);
                setMessageNotif("Rôle ajouter avec succès.");
            } else {
                setSeverityPerso("error");
                setOpenNotif(true);
                setMessageNotif(response.status.message);
            }
          }
        }
        if (haveAccessTo(CURRENT_PAGE, 'edit') && editId) {
            if (
              valuesRole.name
            ) {
              const response = await RoleService.editRole(editId, valuesRole);
              if(response?.status?.code === 200) {
                  let dataResponse = response.data;
                  setRows(
                    rows.map((row) => {
                        const updateData = {
                            ...row,
                            name: dataResponse.name,
                        }
                      return row.id === response.data.id ? updateData : row;
                    })
                  );
                  closeModal();
                  setSeverityPerso("success");
                  setOpenNotif(true);
                  setMessageNotif("Rôle modifier avec succès.");
              } else {
                  setSeverityPerso("error");
                  setOpenNotif(true);
                  setMessageNotif(response.status.message);
              }
            }
          }
    };

    const confirmDelete = async () => {
        const response = await RoleService.deleteRole(deletedId);
        if(response?.status?.code === 200) {
            setSeverityPerso("success");
            setDeletedId(null);
            setMessageNotif(`Utilisateur supprimer avec succès.`);
            setRows(rows.filter((el) => el.id !== deletedId));
            setOpenDeleteConfirm(false);
            setOpenNotif(true);
        }
    }

    return (
        <>
            <Notification
                severity={severityPerso}
                openNotif={openNotif}
                handleClose={handleCloseNotif}
                message={messageNotif}
            />
            <div style={{ padding: 20 }}>
                <div className={classes.headContent}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.iconTitle}>
                            <LockPersonOutlinedIcon style={{ fontSize: '1.1rem' }} />
                        </div>
                        <div>
                            <h4>Rôles</h4>
                            <ButtonComponent style={{marginLeft: '20px', textTransform: 'none'}} text="Nouveau" variant="contained" onClick={() => setOpenAddEdit(true)}/>
                        </div>
                    </div>
                </div>
                <Table columns={columns} rows={rows}/>
            </div>
            <ModalComponent
                openModal={openAddEdit}
                close={() => closeModal()}
                title={'Ajout'}
                submit={() => onSaveNewRole()}
                actionTitle={'Enregistrer'}
                modalContentBody={<ModalContentBody editId={editId} setValuesRole={setValuesRole} valuesRole={valuesRole} isSubmitted={isSubmitted} />}
            />
            <ModalComponent
                openModal={openDeleteConfirm}
                close={() => {
                    setOpenDeleteConfirm(false)
                    setDeletedId(null);
                }}
                title={'Suppression'}
                submit={() => confirmDelete()}
                actionTitle={'Confirmer'}
                modalContentBody={'Est-ce que vous voulez vraiment supprimer ce rôle ?'}
            />
            <DrawerCompenent
                title={'Permission'}
                openDrawer={openPermission}
                toggleDrawer={()=> {
                    setOpenPermission(false);
                    setIdRole(null);
                }}
            >
                <SetPermissionPage
                    roleId={idRole}
                    toggleDrawer={()=> setOpenPermission(false)}
                    setSeverityPerso={setSeverityPerso}
                    setOpenNotif={setOpenNotif}
                    setMessageNotif={setMessageNotif}
                />
            </DrawerCompenent>
        </>
    )
}

export default Roles;